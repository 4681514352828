import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CookiesPage = () => {
  return (
    <Layout>
      <SEO
        title="Cookies"
        description={`Denna hemsida använder kakor (eng. "cookies"). Vi använder kakor för att förbättra din upplevelse när du besöker vår webbplats.`}
      />
      <div className="article-container">
        <h1 className="section-title">Kakor</h1>
        <p>
          Denna hemsida använder kakor (eng. "cookies"). Vi använder kakor för
          att förbättra din upplevelse när du besöker vår webbplats. Kakor
          används även för statistik för att kunna göra förbättringar på
          webbplatsen. Genom att använda får hemsida väljer du att godkänna att
          vi använder kakor under ditt besök.
        </p>
        <p>
          Kakor (eng. "cookies") kan användas för att skräddarsy innehåll efter
          anpassad sortering eller urval.
        </p>
      </div>
    </Layout>
  )
}

export default CookiesPage
